import React from 'react';
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { onDragEnd } from "../resources/utils";
import styles from './Account.module.scss';
import ProductCard from "../components/ProductCard";
import Welcomer from "../components/Welcomer";

const variants = {
  enter: {
    x: 1000,
    opacity: 0
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    zIndex: 0,
    x: -1000,
    opacity: 0
  }
};

const Account = ({ customer }) => {
  let history = useHistory();
  return (
    <motion.div
    className={styles.account}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
    drag="x"
    dragConstraints={{ left: 0, right: 0 }}
    dragElastic={0.5}
    onDragEnd={(e, { offset, velocity }) => {
      onDragEnd(
        offset,
        velocity,
        () => {
          history.goBack();
        },
        () => {
          // do nothing
        },
      )
    }}
    > 
    
      <Welcomer customer={customer} />
  
      <div className={styles.rewards}>
        <strong>Available Rewards</strong>
        {
          (customer && customer.rewards) ? customer.rewards.sort((a,b) => a.points - b.points).map(x => {
            return (
              <ProductCard key={x.id} reward={x} />
            )
          }) : <></>
        }
      </div>
    </motion.div>
  )
}

export default Account;
