import React, { useState } from 'react';
import { motion } from "framer-motion";
import { useHistory, useParams, Link } from "react-router-dom";
import { onDragEnd, goToCheckout, redeemReward } from "../resources/utils";
import copy from 'copy-to-clipboard';
import shaker from "../resources/shaker.jpeg";

import styles from './Confirm.module.scss';

import Loading from "../components/Loading";
import Welcomer from "../components/Welcomer";

const variants = {
  enter: {
    x: -1000,
    opacity: 0
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    zIndex: 0,
    x: 1000,
    opacity: 0
  }
};

const Confirm = ({ customer }) => {
  const [isLoading, toggleLoading] = useState(false);
  const [code, setCode] = useState(null);

  let history = useHistory();
  let { rewardID } = useParams();

  let reward = customer.rewards.find(x => x.id === rewardID);
  console.log(customer, Boolean(customer && customer.email))
  if(!(customer && customer.email)) {
    return (
      <motion.div
      className={styles.confirm}
      data-has-footer={Boolean(customer && customer.email)}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.5}
      onDragEnd={(e, { offset, velocity }) => {
        onDragEnd(
          offset,
          velocity,
          () => {
            history.goBack();
          },
          () => {
            // do nothing
          },
        )
      }}
      >
        <div onClick={() => { history.goBack(); }} className={styles.breadcrumb}>
          <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.19752 0.123583L5.46584 0.372138C5.64366 0.53688 5.64366 0.803996 5.46584 0.968773L2.28107 3.90235H16.5446C16.7961 3.90235 17 4.09124 17 4.32422V4.67579C17 4.90877 16.7961 5.09766 16.5446 5.09766H2.28107L5.46584 8.03124C5.64366 8.19598 5.64366 8.46309 5.46584 8.62787L5.19752 8.87643C5.0197 9.04117 4.73139 9.04117 4.55357 8.87643L0.133383 4.7983C-0.0444355 4.63356 -0.0444355 4.36645 0.133383 4.20167L4.55357 0.123583C4.73139 -0.0411943 5.0197 -0.0411943 5.19752 0.123583Z" fill="#1D1D1E"/>
          </svg>
          <span>Return</span>
        </div>
        <div className={styles.rewardMain}>
          <div data-image-wrapper>
          {
            (reward && reward.data && reward.data.thumbnail) ?
            <img src={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${reward.data.thumbnail}`} alt="shaker" />
            :
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 144h-39.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C213.3 41.4 188.8 16 146.5 16 105.4 16 72 51.9 72 96c0 18.1 5.8 34.6 15.3 48H48c-26.5 0-48 21.5-48 48v96c0 8.8 7.2 16 16 16h16v144c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304h16c8.8 0 16-7.2 16-16v-96c0-26.5-21.5-48-48-48zm-187.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H274l2.2-3.6zM146.5 48c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM192 464H80c-8.8 0-16-7.2-16-16V304h128v160zm0-192H32v-80c0-8.8 7.2-16 16-16h144v96zm96 192h-64V176h64v288zm160-16c0 8.8-7.2 16-16 16H320V304h128v144zm32-176H320v-96h144c8.8 0 16 7.2 16 16v80z"></path></svg>
          }
          </div>
          <div>
            <strong>{ reward.name }</strong>
            <p><strong>{reward.points} points</strong> will be deducted from your rewards balance. Loyalty points cannot be refunded. Not to be used in conjunction with any other offer.</p>
            {
              (reward && reward.data && reward.data.description) ? <p>{reward.data.description}</p> : <span></span>
            }
          </div>
        </div>
        <div onClick={() => { history.goBack(); }} className={styles.buttonSub}>Return</div>
      </motion.div>
    )
  }

  if(isLoading) {
    return (
      <motion.div
      className={styles.confirmLoading}
      data-has-footer={false}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.5}
      onDragEnd={(e, { offset, velocity }) => {
        onDragEnd(
          offset,
          velocity,
          () => {
            history.goBack();
          },
          () => {
            // do nothing
          },
        )
      }}
      >
        <Loading />
      </motion.div>
    )
  }

  if(code) {
    return (
      <motion.div
      className={styles.confirmFinished}
      data-has-footer={Boolean(customer && customer.email)}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.5}
      onDragEnd={(e, { offset, velocity }) => {
        onDragEnd(
          offset,
          velocity,
          () => {
            history.push('/');
          },
          () => {
            // do nothing
          },
        )
      }}
      >
        <h1>Offer redeemed!</h1>
        <div data-image-wrapper>
          {
            (reward && reward.data && reward.data.thumbnail) ?
            <img src={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${reward.data.thumbnail}`} alt="shaker" />
            :
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 144h-39.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C213.3 41.4 188.8 16 146.5 16 105.4 16 72 51.9 72 96c0 18.1 5.8 34.6 15.3 48H48c-26.5 0-48 21.5-48 48v96c0 8.8 7.2 16 16 16h16v144c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304h16c8.8 0 16-7.2 16-16v-96c0-26.5-21.5-48-48-48zm-187.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H274l2.2-3.6zM146.5 48c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM192 464H80c-8.8 0-16-7.2-16-16V304h128v160zm0-192H32v-80c0-8.8 7.2-16 16-16h144v96zm96 192h-64V176h64v288zm160-16c0 8.8-7.2 16-16 16H320V304h128v144zm32-176H320v-96h144c8.8 0 16 7.2 16 16v80z"></path></svg>
          }
        </div>
        <strong>{ reward.title }</strong>

        <p>Use this code at the checkout:</p>
        <motion.div whileTap={{ scale: 0.9 }} className={styles.copy} onClick={() => {
          copy(code);
        }}>
          <span>{code}</span>
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5938 0H4.21875C3.44209 0 2.8125 0.62959 2.8125 1.40625V2.8125H1.40625C0.62959 2.8125 0 3.44209 0 4.21875V13.5938C0 14.3704 0.62959 15 1.40625 15H10.7812C11.5579 15 12.1875 14.3704 12.1875 13.5938V12.1875H13.5938C14.3704 12.1875 15 11.5579 15 10.7812V1.40625C15 0.62959 14.3704 0 13.5938 0ZM10.6055 13.5938H1.58203C1.53541 13.5938 1.4907 13.5752 1.45774 13.5423C1.42477 13.5093 1.40625 13.4646 1.40625 13.418V4.39453C1.40625 4.34791 1.42477 4.3032 1.45774 4.27024C1.4907 4.23727 1.53541 4.21875 1.58203 4.21875H2.8125V10.7812C2.8125 11.5579 3.44209 12.1875 4.21875 12.1875H10.7812V13.418C10.7812 13.4646 10.7627 13.5093 10.7298 13.5423C10.6968 13.5752 10.6521 13.5938 10.6055 13.5938ZM13.418 10.7812H4.39453C4.34791 10.7812 4.3032 10.7627 4.27024 10.7298C4.23727 10.6968 4.21875 10.6521 4.21875 10.6055V1.58203C4.21875 1.53541 4.23727 1.4907 4.27024 1.45774C4.3032 1.42477 4.34791 1.40625 4.39453 1.40625H13.418C13.4646 1.40625 13.5093 1.42477 13.5423 1.45774C13.5752 1.4907 13.5938 1.53541 13.5938 1.58203V10.6055C13.5938 10.6521 13.5752 10.6968 13.5423 10.7298C13.5093 10.7627 13.4646 10.7812 13.418 10.7812Z" fill="#BBBCC7"/>
          </svg>
        </motion.div>

        <div onClick={async() => {
          await goToCheckout(code, reward);
          toggleLoading(true);
        }} className={styles.button}>Go to checkout</div>
        <Link to="/" className={styles.buttonSub}>My account</Link>
      </motion.div>
    )
  }

  if(customer.loyalty_points < reward.points) {
    return (
      <motion.div
      className={styles.confirm}
      data-has-footer={Boolean(customer && customer.email)}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.5}
      onDragEnd={(e, { offset, velocity }) => {
        onDragEnd(
          offset,
          velocity,
          () => {
            history.goBack();
          },
          () => {
            // do nothing
          },
        )
      }}
      >
        {
          customer &&
          <Welcomer customer={customer} />
        }
        <div onClick={() => { history.goBack(); }} className={styles.breadcrumb}>
          <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.19752 0.123583L5.46584 0.372138C5.64366 0.53688 5.64366 0.803996 5.46584 0.968773L2.28107 3.90235H16.5446C16.7961 3.90235 17 4.09124 17 4.32422V4.67579C17 4.90877 16.7961 5.09766 16.5446 5.09766H2.28107L5.46584 8.03124C5.64366 8.19598 5.64366 8.46309 5.46584 8.62787L5.19752 8.87643C5.0197 9.04117 4.73139 9.04117 4.55357 8.87643L0.133383 4.7983C-0.0444355 4.63356 -0.0444355 4.36645 0.133383 4.20167L4.55357 0.123583C4.73139 -0.0411943 5.0197 -0.0411943 5.19752 0.123583Z" fill="#1D1D1E"/>
          </svg>
          <span>Redeem offer?</span>
        </div>

        <div className={styles.rewardMain}>
          <div data-image-wrapper>
            {
              (reward && reward.data && reward.data.thumbnail) ?
              <img src={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${reward.data.thumbnail}`} alt="shaker" />
              :
              <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 144h-39.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C213.3 41.4 188.8 16 146.5 16 105.4 16 72 51.9 72 96c0 18.1 5.8 34.6 15.3 48H48c-26.5 0-48 21.5-48 48v96c0 8.8 7.2 16 16 16h16v144c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304h16c8.8 0 16-7.2 16-16v-96c0-26.5-21.5-48-48-48zm-187.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H274l2.2-3.6zM146.5 48c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM192 464H80c-8.8 0-16-7.2-16-16V304h128v160zm0-192H32v-80c0-8.8 7.2-16 16-16h144v96zm96 192h-64V176h64v288zm160-16c0 8.8-7.2 16-16 16H320V304h128v144zm32-176H320v-96h144c8.8 0 16 7.2 16 16v80z"></path></svg>
            }
          </div>
          <div>
            <strong>{ reward.name }</strong>
            <p>You are <strong>{reward.points - customer.loyalty_points} points</strong> off being able to redeem this reward.</p>
          </div>
        </div>
      
        <div onClick={() => { history.goBack(); }} className={styles.buttonSub}>Go back</div>
        <div className={styles.links}>
          <Link to="/how" className={styles.link}>
            <span>How can I earn points?</span>
            <div>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
            </div>
          </Link>
        </div>
      </motion.div>
    )
  }



  return (
    <motion.div
    className={styles.confirm}
    data-has-footer={Boolean(customer && customer.email)}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
    drag="x"
    dragConstraints={{ left: 0, right: 0 }}
    dragElastic={0.5}
    onDragEnd={(e, { offset, velocity }) => {
      onDragEnd(
        offset,
        velocity,
        () => {
          history.goBack();
        },
        () => {
          // do nothing
        },
      )
    }}
    >
      {
        customer &&
        <Welcomer customer={customer} />
      }
      <div onClick={() => { history.goBack(); }} className={styles.breadcrumb}>
        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.19752 0.123583L5.46584 0.372138C5.64366 0.53688 5.64366 0.803996 5.46584 0.968773L2.28107 3.90235H16.5446C16.7961 3.90235 17 4.09124 17 4.32422V4.67579C17 4.90877 16.7961 5.09766 16.5446 5.09766H2.28107L5.46584 8.03124C5.64366 8.19598 5.64366 8.46309 5.46584 8.62787L5.19752 8.87643C5.0197 9.04117 4.73139 9.04117 4.55357 8.87643L0.133383 4.7983C-0.0444355 4.63356 -0.0444355 4.36645 0.133383 4.20167L4.55357 0.123583C4.73139 -0.0411943 5.0197 -0.0411943 5.19752 0.123583Z" fill="#1D1D1E"/>
        </svg>
        <span>Redeem offer?</span>
      </div>

      <div className={styles.rewardMain}>
        <div data-image-wrapper>
          {
            (reward && reward.data && reward.data.thumbnail) ?
            <img src={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${reward.data.thumbnail}`} alt="shaker" />
            :
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 144h-39.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C213.3 41.4 188.8 16 146.5 16 105.4 16 72 51.9 72 96c0 18.1 5.8 34.6 15.3 48H48c-26.5 0-48 21.5-48 48v96c0 8.8 7.2 16 16 16h16v144c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304h16c8.8 0 16-7.2 16-16v-96c0-26.5-21.5-48-48-48zm-187.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H274l2.2-3.6zM146.5 48c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM192 464H80c-8.8 0-16-7.2-16-16V304h128v160zm0-192H32v-80c0-8.8 7.2-16 16-16h144v96zm96 192h-64V176h64v288zm160-16c0 8.8-7.2 16-16 16H320V304h128v144zm32-176H320v-96h144c8.8 0 16 7.2 16 16v80z"></path></svg>
          }
        </div>
        <div>
          <strong>{ reward.name }</strong>
          <p><strong>{reward.points} points</strong> will be deducted from your rewards balance. Loyalty points cannot be refunded. Not to be used in conjunction with any other offer.</p>
          {
            (reward && reward.data && reward.data.description) ? <p>{reward.data.description}</p> : <span></span>
          }
        </div>
      </div>

      <div onClick={async() => {
        toggleLoading(true);
        let result = await redeemReward(customer, rewardID);
        if(result && result.code) {
          setCode(result.code);
          toggleLoading(false);
        }
      }} className={styles.button}
      style={{ backgroundColor: customer.settings.loyalty_popup_color }}
      >Redeem</div>
      <div onClick={() => { history.goBack(); }} className={styles.buttonSub}>Cancel</div>
    </motion.div>
  )
}

export default Confirm;
