/* eslint-disable */
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// screens
import SignIn from "./screens/SignIn";
import How from "./screens/How";
import What from "./screens/What";
import Account from "./screens/Account";

import Reward from "./screens/Reward";
import Activity from "./screens/Activity";
import Confirm from "./screens/Confirm";

// Components
import Loading from "./components/Loading";
import Header from "./components/Header";
import Footer from "./components/Footer";

import styles from './App.module.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customer: null,
    };
  }

  getCustomer = async(email, hmac, org, preview = false, presets = null) => {
    this.setState({ loading: true }, async() => {
      let result = await fetch(`https://api.audiencezen.com/public/loyalty`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
          email,
          hmac,
          org,
        })
      }).then(r => r.json());

      if(result && result.settings && result.settings.loyalty_enabled && result.settings.loyalty_popup_enabled) {

        window.parent.postMessage({
          s_s_l: true,
          topic: "INIT"
        }, "*");

        this.setState({
          customer: {
          ...result,
          ...result.customer,
          email,
          hmac,
          ...(preview) && {
            activity: [],
            first_name: "Preview User",
            loyalty_points: 0,
            email: "test@example.com"
          },
          ...(presets) && {
            settings: {
              ...result.settings,
              loyalty_explainer_text: presets.explainer,
              loyalty_program_points_per_dollar: presets.pointsPerDollar,
              loyalty_popup_logo: presets.logo,
              loyalty_popup_banner: presets.banner,
              loyalty_popup_color: presets.popupColor,
            }
          },
          org
          },
          loading: false
        });
      }
    });
  }

  onMessage = async(e) => {
    if(e.data && e.data.org) {
      this.getCustomer(e.data.email, e.data.hmac, e.data.org, e.data.preview, e.data.presets);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  async componentDidMount() {
    window.addEventListener('message', this.onMessage);
  }

  renderRoot = () => {
    const { customer } = this.state;
    if(customer && customer.email) {
      return <Account customer={customer} />
    } else {
      return <SignIn customer={customer} />
    }
  }

  render() {
    const { loading, customer } = this.state;
    if(loading) {
      return (
        <div className={styles.popup}>
          <Loading />
        </div>
      )
    }

    return (
      <div className={styles.popup} >
        <Router>
        <Header state={this.state} />
          <AnimatedSwitch state={this.state} renderRoot={this.renderRoot.bind(this)} />
          {
            (customer && customer.email) &&
            <Footer customer={customer}/>
          }
        </Router>
      </div>
    );
  }
}

const AnimatedSwitch = ({ state, renderRoot }) => {
  const { customer } = state;
  const location = useLocation();
  return (
    <AnimatePresence initial={false}>
      <Switch location={location} key={location.pathname}>
        <Route path="/how">
          <How customer={customer} />
        </Route>
        <Route path="/what">
          <What customer={customer} />
        </Route>
        <Route path="/rewards">
          <RewardsSwitch customer={customer} />
        </Route>
        <Route path="/activity">
          <Activity customer={customer} />
        </Route>
        <Route path="/">
          { renderRoot }
        </Route>
      </Switch>
    </AnimatePresence>
  )
}

function RewardsSwitch({ config, customer, rewards }) {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/confirm/:rewardID`}>
        <Confirm customer={customer} />
      </Route>
      <Route path={`${match.path}/:rewardID`}>
        <Reward customer={customer} />
      </Route>
    </Switch>
  );
}

export default App;
