import React from 'react';
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { onDragEnd } from "../resources/utils";

import Welcomer from "../components/Welcomer";

import styles from './How.module.scss';

const variants = {
  enter: {
    x: -1000,
    opacity: 0
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    zIndex: 0,
    x: 1000,
    opacity: 0
  }
};

const How = ({ customer }) => {
  let history = useHistory();
  return (
    <motion.div
    className={styles.how}
    data-has-footer={Boolean(customer)}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
    drag="x"
    dragConstraints={{ left: 0, right: 0 }}
    dragElastic={0.5}
    onDragEnd={(e, { offset, velocity }) => {
      onDragEnd(
        offset,
        velocity,
        () => {
          history.goBack();
        },
        () => {
          // do nothing
        },
      )
    }}
    >
      {
        customer &&
        <Welcomer customer={customer} />
      }
      <div onClick={() => { history.goBack(); }} className={styles.breadcrumb}>
        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.19752 0.123583L5.46584 0.372138C5.64366 0.53688 5.64366 0.803996 5.46584 0.968773L2.28107 3.90235H16.5446C16.7961 3.90235 17 4.09124 17 4.32422V4.67579C17 4.90877 16.7961 5.09766 16.5446 5.09766H2.28107L5.46584 8.03124C5.64366 8.19598 5.64366 8.46309 5.46584 8.62787L5.19752 8.87643C5.0197 9.04117 4.73139 9.04117 4.55357 8.87643L0.133383 4.7983C-0.0444355 4.63356 -0.0444355 4.36645 0.133383 4.20167L4.55357 0.123583C4.73139 -0.0411943 5.0197 -0.0411943 5.19752 0.123583Z" fill="#1D1D1E"/>
        </svg>
        <span>How does it work?</span>
      </div>
      <div className={styles.cards}>
        <motion.div className={styles.info} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{
          y: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 }
        }}> 
          <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="smile" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill={`${customer.settings.loyalty_popup_color}`} d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm90.2-146.2C315.8 352.6 282.9 368 248 368s-67.8-15.4-90.2-42.2c-5.7-6.8-15.8-7.7-22.5-2-6.8 5.7-7.7 15.7-2 22.5C161.7 380.4 203.6 400 248 400s86.3-19.6 114.8-53.8c5.7-6.8 4.8-16.9-2-22.5-6.8-5.6-16.9-4.7-22.6 2.1zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"></path></svg>
            <span>Shop to earn</span>
          </div>
          <span>Earn {customer.settings.loyalty_program_points_per_dollar} point{customer.settings.loyalty_program_points_per_dollar > 1 ? 's' : ''} for every $1 spend.</span>
        </motion.div>
        <motion.div className={styles.info} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{
          y: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 }
        }}>
          <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill={`${customer.settings.loyalty_popup_color}`} d="M464 144h-39.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C213.3 41.4 188.8 16 146.5 16 105.4 16 72 51.9 72 96c0 18.1 5.8 34.6 15.3 48H48c-26.5 0-48 21.5-48 48v96c0 8.8 7.2 16 16 16h16v144c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304h16c8.8 0 16-7.2 16-16v-96c0-26.5-21.5-48-48-48zm-187.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H274l2.2-3.6zM146.5 48c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM192 464H80c-8.8 0-16-7.2-16-16V304h128v160zm0-192H32v-80c0-8.8 7.2-16 16-16h144v96zm96 192h-64V176h64v288zm160-16c0 8.8-7.2 16-16 16H320V304h128v144zm32-176H320v-96h144c8.8 0 16 7.2 16 16v80z"></path></svg>
            <span>Spend your points</span>
          </div>
          <span>Spend your loyalty points on special offers and discount codes.</span>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default How;
