import React from 'react';
import Media from "./Media";
import styles from './Welcomer.module.scss';
import logo from "../resources/logo.png"

const Welcomer = ({ customer }) => {
  if(!(customer && customer.email)) {
    return <></>
  }

  return (
    <div className={styles.top}>
      <Media image={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${customer.settings.loyalty_popup_logo}`} />
      <div>
        { customer.first_name && <span>Hi, {customer.first_name}!</span>}
        <span>You have <strong>{ (customer.loyalty_points ? customer.loyalty_points : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</strong> points.</span>
      </div>
    </div>
  )
}

export default Welcomer;
