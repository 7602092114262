import React from 'react';
import { motion } from "framer-motion";
import { useHistory, useParams, Link } from "react-router-dom";
import { onDragEnd } from "../resources/utils";
import logo from "../resources/logo.png";
import styles from './Reward.module.scss';
import Media from "../components/Media";
import Welcomer from "../components/Welcomer";

const variants = {
  enter: {
    x: -1000,
    opacity: 0
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    zIndex: 0,
    x: 1000,
    opacity: 0
  }
};

const Reward = ({ customer }) => {
  let history = useHistory();
  let { rewardID } = useParams();

  let reward = customer.rewards.find(x => x.id === rewardID);

  return (
    <motion.div
    className={styles.reward}
    data-has-footer={Boolean(customer && customer.email)}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
    drag="x"
    dragConstraints={{ left: 0, right: 0 }}
    dragElastic={0.5}
    onDragEnd={(e, { offset, velocity }) => {
      onDragEnd(
        offset,
        velocity,
        () => {
          history.goBack();
        },
        () => {
          // do nothing
        },
      )
    }}
    
    >
      {
        customer &&
        <Welcomer customer={customer} />
      }
      <div onClick={() => { history.goBack(); }} className={styles.breadcrumb}>
        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.19752 0.123583L5.46584 0.372138C5.64366 0.53688 5.64366 0.803996 5.46584 0.968773L2.28107 3.90235H16.5446C16.7961 3.90235 17 4.09124 17 4.32422V4.67579C17 4.90877 16.7961 5.09766 16.5446 5.09766H2.28107L5.46584 8.03124C5.64366 8.19598 5.64366 8.46309 5.46584 8.62787L5.19752 8.87643C5.0197 9.04117 4.73139 9.04117 4.55357 8.87643L0.133383 4.7983C-0.0444355 4.63356 -0.0444355 4.36645 0.133383 4.20167L4.55357 0.123583C4.73139 -0.0411943 5.0197 -0.0411943 5.19752 0.123583Z" fill="#1D1D1E"/>
        </svg>
        <span>{ reward.name }</span>
      </div>

      <div className={styles.rewardMain}>
        <div data-image-wrapper>
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 144h-39.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C213.3 41.4 188.8 16 146.5 16 105.4 16 72 51.9 72 96c0 18.1 5.8 34.6 15.3 48H48c-26.5 0-48 21.5-48 48v96c0 8.8 7.2 16 16 16h16v144c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304h16c8.8 0 16-7.2 16-16v-96c0-26.5-21.5-48-48-48zm-187.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H274l2.2-3.6zM146.5 48c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM192 464H80c-8.8 0-16-7.2-16-16V304h128v160zm0-192H32v-80c0-8.8 7.2-16 16-16h144v96zm96 192h-64V176h64v288zm160-16c0 8.8-7.2 16-16 16H320V304h128v144zm32-176H320v-96h144c8.8 0 16 7.2 16 16v80z"></path></svg>
        </div>
        <div>
          <strong>{ reward.name }</strong>
          <p className={styles.details}><strong>{reward.points} points.</strong> Not to be used in conjunction with any other offer.</p>
        </div>
      </div>

      {
        (customer && customer.email) &&
        <Link to={`/rewards/confirm/${rewardID}`} className={styles.button} >Redeem</Link>
      }
    </motion.div>
  )
}

export default Reward;
