import React, {Component} from 'react';
import { motion } from "framer-motion";

import styles from './ActivityList.module.scss';

import Loading from "./Loading";

class ActivityList extends Component {

  render() {
    const { customer } = this.props;
    if(!customer) {
      return (
        <div>
          <Loading />
        </div>
      )
    }
    if(!customer.activity.length) {
      return (
        <p>No loyalty activity.</p>
      )
    }
    return (
      <div className={styles.cards}>
      {
        [ ...customer.activity ].sort((a,b) => new Date(b.created_at) - new Date(a.created_at)).map(x => {
          return (
            <motion.div className={styles.card} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} key={x.created_at} transition={{
              y: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}>
              <span><strong>Event date: </strong>{new Date(x.created_at).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})}</span>
              <span><strong>Points change: </strong>{x.values.points}</span>
              <div>
                <strong className={styles.details}>Details:</strong>
                {x.values.reason && <span>{ x.values.reason }</span>}
                {x.values.code && <span>Code: { x.values.code }</span>}
              </div>
            </motion.div>
          )
        })
      }
      </div>
    );
  }
}

export default ActivityList;
