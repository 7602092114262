import React from 'react';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { motion } from "framer-motion";

import styles from './Footer.module.scss';

const Footer = () => {
  const location = useLocation();
  let history = useHistory();
  return (
    <div className={styles.footer} >
      <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-active-tab={location.pathname === '/'} onClick={() => { history.push('/') }} className={styles.link}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528 128h-58.07c6.22-12.06 10.07-25.52 10.07-40 0-48.52-39.48-88-88-88-41.6 0-68.51 21.34-103.04 68.33C254.44 21.33 227.53 0 185.93 0c-48.52 0-88 39.48-88 88 0 14.48 3.85 27.94 10.07 40H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zM392 48c22.06 0 40 17.94 40 40 0 22.05-17.94 40-40 40h-86.07c51.36-76.47 65.72-80 86.07-80zM145.93 88c0-22.06 17.94-40 40-40 19.94 0 34.58 3.27 86.07 80h-86.07c-22.06 0-40-17.95-40-40zm60.13 104l-35.72 35.72c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0L273.94 192h28.13l69.65 69.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L369.94 192H512v128H64V192h142.06zM64 448v-64h448v64H64z"/></svg>
      <span>Rewards</span>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-active-tab={location.pathname.includes('/activity')} onClick={() => { history.push('/activity') }} className={styles.link}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"/></svg>
      <span>Activity</span>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-active-tab={location.pathname.includes('/how')} onClick={() => { history.push('/how') }} className={styles.link}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
      <span>Info</span>
      </motion.div>
    </div>
  )
}

export default Footer;
