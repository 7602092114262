export async function goToCheckout(code, reward) {
  window.parent.postMessage({
    s_s_l: true,
    topic: "CHECKOUT",
    code: code,
    ...(reward && reward.data && reward.data.add_to_cart && reward.data.shopify_id ) && { productToAdd: reward.data.shopify_id },
  }, "*");
}

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const onDragEnd = (offset, velocity, onLeft = () => {}, onRight = () => {}) => {
  const swipe = swipePower(offset.x, velocity.x);
  if (swipe < -swipeConfidenceThreshold) {
    onRight();
  } else if (swipe > swipeConfidenceThreshold) {
    onLeft();
  }
}

export async function redeemReward(customer, rewardId) {
  let result = await fetch(`https://api.audiencezen.com/public/loyalty/redeem`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type' : 'application/json'
    },
    body: JSON.stringify({
      rewardId,
      email: customer.email,
      org: customer.org,
      hmac: customer.hmac
    })
  }).then(r => r.json());

  if(result) {
    return result;
  } else {
    return null;
  }
}
