import React from 'react';
import { motion } from "framer-motion";
import {
  Link,
} from "react-router-dom";
import logo from "../resources/logo.png";

import Media from "../components/Media";

import styles from './SignIn.module.scss';

const variants = {
  enter: {
    x: 1000,
    opacity: 0
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    zIndex: 0,
    x: -1000,
    opacity: 0
  }
};

const SignIn = ({ customer }) => {

  return (
    <motion.div
    className={styles.signin}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
    drag="x"
    dragConstraints={{ left: 0, right: 0 }}
    dragElastic={0.5}
    >
      <Media image={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${customer.settings.loyalty_popup_logo}`} />
      <p>{ customer.settings.loyalty_explainer_text }</p>
      <button onClick={() => {
        window.parent.postMessage({
          s_s_l: true,
          topic: "SIGN_IN"
        }, "*");
      }}
      style={{ backgroundColor: customer.settings.loyalty_popup_color }}>
        <span  >Sign in or register</span>
      </button>
      <div className={styles.links}>
        <Link to="/how" className={styles.link}>
          <span>How does it work?</span>
          <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
          </div>
        </Link>
        <Link to="/what" className={styles.link}>
          <span>What can I get?</span>
          <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
          </div>
        </Link>
      </div>
    </motion.div>
  )
}

export default SignIn;
