import React from 'react';

import styles from './Header.module.scss';

const Header = ({ state }) => {
  return (
    <div className={styles.close_wrapper} style={{height: state.customer.settings.loyalty_popup_banner ? '200px' : '0px'}}>
     {state.customer.settings.loyalty_popup_banner ? 
     <img src={`https://versant-assets.s3.ap-southeast-2.amazonaws.com/${state.customer.settings.loyalty_popup_banner}`}/>
      : null} 
      <div onClick={() => {
      window.parent.postMessage({
      s_s_l: true,
      topic: "CLOSE"
      }, "*");
      }} className={styles.close}>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.09094 7.5L13.8984 2.6925L14.8898 1.70109C15.0361 1.55484 15.0361 1.31719 14.8898 1.17094L13.8291 0.110156C13.6828 -0.0360936 13.4452 -0.0360936 13.2989 0.110156L7.5 5.90906L1.70109 0.109687C1.55484 -0.0365625 1.31719 -0.0365625 1.17094 0.109687L0.109687 1.17047C-0.0365625 1.31672 -0.0365625 1.55438 0.109687 1.70063L5.90906 7.5L0.109687 13.2989C-0.0365625 13.4452 -0.0365625 13.6828 0.109687 13.8291L1.17047 14.8898C1.31672 15.0361 1.55437 15.0361 1.70062 14.8898L7.5 9.09094L12.3075 13.8984L13.2989 14.8898C13.4452 15.0361 13.6828 15.0361 13.8291 14.8898L14.8898 13.8291C15.0361 13.6828 15.0361 13.4452 14.8898 13.2989L9.09094 7.5Z" fill="#1D1D1E"/>
      </svg>
      </div>
    </div>
  )
}

export default Header;
