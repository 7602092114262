import React from 'react';
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { onDragEnd } from "../resources/utils";

import ProductCard from "../components/ProductCard";
import Welcomer from "../components/Welcomer";

import styles from './What.module.scss';

const variants = {
  enter: {
    x: -1000,
    opacity: 0
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: {
    zIndex: 0,
    x: 1000,
    opacity: 0
  }
};

const What = ({ customer }) => {
  let history = useHistory();
  return (
    <motion.div
    className={styles.what}
    data-has-footer={Boolean(customer)}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
    drag="x"
    dragConstraints={{ left: 0, right: 0 }}
    dragElastic={0.5}
    onDragEnd={(e, { offset, velocity }) => {
      onDragEnd(
        offset,
        velocity,
        () => {
          history.goBack();
        },
        () => {
          // do nothing
        },
      )
    }}
    >
      {
        customer &&
        <Welcomer customer={customer} />
      }
      <div onClick={() => { history.goBack(); }} className={styles.breadcrumb}>
        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.19752 0.123583L5.46584 0.372138C5.64366 0.53688 5.64366 0.803996 5.46584 0.968773L2.28107 3.90235H16.5446C16.7961 3.90235 17 4.09124 17 4.32422V4.67579C17 4.90877 16.7961 5.09766 16.5446 5.09766H2.28107L5.46584 8.03124C5.64366 8.19598 5.64366 8.46309 5.46584 8.62787L5.19752 8.87643C5.0197 9.04117 4.73139 9.04117 4.55357 8.87643L0.133383 4.7983C-0.0444355 4.63356 -0.0444355 4.36645 0.133383 4.20167L4.55357 0.123583C4.73139 -0.0411943 5.0197 -0.0411943 5.19752 0.123583Z" fill="#1D1D1E"/>
        </svg>
        <span>What can I get?</span>
      </div>
      <span>Earn loyalty points spend on rewards.</span>
      <div className={styles.rewards}>
        {
          (customer && customer.rewards) ? customer.rewards.sort((a,b) => a.points - b.points).map(x => {
            return (
              <ProductCard key={x.id} reward={x} />
            )
          }) : <></>
        }
      </div>
    </motion.div>
  )
}

export default What;
